$(document).ready(function() {

	// perquè funcioni bé el top-bar
	$('body').removeAttr('style');

  // seleccionem items per ajustar borders
  $('.main-nav .active').prev().addClass('prev');
  $('.main-nav li:last-child').addClass('last');

  // perquè funcioni l'skip link correctament (http://terrillthompson.com/blog/161)
	$('#main, #main-nav').attr('tabindex', -1);
	// add a click handler to all links that point to same-page targets (href="#...")
	$("a[href^='#']").click(function() {
		// get the href attribute of the internal link then strip the first character off it (#) leaving the corresponding id attribute
		$("#"+$(this).attr("href").slice(1)+"")
		// give that id focus (for browsers that didn't already do so)
		.focus()
  });
  $('.amagar').hide();
  $('.more').click(function(){
  	$('.amagar').show();
  	$('.mes').hide();
  });
	 // smooth scroll top
    $(window).scroll(function() {
        if ($(this).scrollTop() > 800) {
            $(".pujar").fadeIn()
        } else {
            $(".pujar").fadeOut()
        }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"], .js-mobile-menu, .js-contact, .main-nav__item a)').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });
    $('.pest').first().addClass('active');
    $('.publi').last().addClass('noborder');
});
$(document).foundation({
  orbit: {
    animation: 'slide',
    timer_speed: 1000,
    pause_on_hover: true,
    animation_speed: 500,
    navigation_arrows: true,
    bullets: false
  },
  tab: {
      callback : function (tab) {
        console.log(tab);
      }
    }
});
